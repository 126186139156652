/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "../../../variables";

.CheckApp {
    flex: 1;
    background-color: #ffffff;
    display: contents;

    .report {
        min-height: 680px;
    }

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #f4f4f4;
        flex-direction: column;
    }

    .loading {
        padding: 1em 2em 1em 2em;
        flex: 1;
        align-items: start;
        background-color: white;
    }

    .global-container {
        padding: 15% 0;
        margin: 0;
        text-align: center;
        flex: 1;
        background-color: white;
    }
    .centered-container {
        width: 45%;
        margin: 0 auto;
        padding: 3em 3em 3em 3em;
        border: 1px solid #dddddd;
        border-radius: 20px;
        flex-direction: column;
        min-width: 400px;
        color: white;

        a {
            color: $primary-color-dark;
        }
        a:hover {
            color: $contrast-color;
        }
    }

    .result-container {
        text-align: center;
        padding: 1em 2em 1em 2em;
        flex: 1;
        align-items: start;
        background-color: white;
    }

    .validated-container {
        text-align: center;
        padding: 1em 2em 1em 2em;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #dddddd;
        flex: 1;
        align-items: start;
        background-color: white;
    }

    .text-block {
        padding-top: 1em;
    }

    .learn-more-block {
        padding-top: 1em;
    }

    .links {
        margin: 0em 0 1em 0 !important;
    }

    .search-bar-center {
        margin-top: 20px;
    }

    .search-bar-top {
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .search-text {
        margin-right: 2em;
        display: flex;
        align-items: center;
    }

    .result {
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
    }

    /* Base input styles */
    .custom-input {
        width: 100%;
        padding: 12px 15px;
        line-height: 1.5;
        background-color: #fff;
        border: 2px solid #ccc;
        border-radius: 0.5em;
        outline: none; /* Removes default focus outline */
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /*  inner shadow */
        transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth interactions */
        flex: 1;
    }

    /* Focus styles (to be confirmed) */
    .custom-input:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Outer glow for focus */
    }
    .custom-input:disabled {
        background-color: #f9f9f9;
        border-color: #ddd;
        color: #aaa;
        cursor: not-allowed;
    }
    .custom-input:hover {
        border-color: #888;
    }
    .custom-input.error {
        border-color: #dc3545;
        background-color: #f8d7da;
        color: #721c24;
    }
    .custom-input.success {
        border-color: #28a745;
        background-color: #d4edda;
        color: #155724;
    }
    .custom-input::placeholder {
        color: #aaa;
    }
    .error-message {
        font-size: 14px;
    }
    .success-message {
        font-size: 14px;
        color: #28a745;
        margin-top: 5px;
    }

    // removing the DNA of the hero block stale
    .hero::before,
    .hero::after {
        content: none !important;
        display: none !important;
    }
    .hero {
        flex: 1;
        > div {
            margin: 0 0;
            height: 100%;
        }
    }
}
