/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

.ContactButton {
    .button-container {
        margin-top: 2rem;
        display: flex;
    }

    .confirmation {
        margin: 1rem 0 0 0;
    }
    .contact-button {
        height: 70px;
    }
}
