/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "./../../../variables";

.Onboarding {
    flex: 1 1;
    display: flex;

    .hero {
        padding: 13em 0 7em 7em;
        flex: 1 1;
        @media only screen and (max-width: $breakpoint-mobile) {
            padding: 4em 2em 6em 2em;
            flex-direction: column;
        }
    }

    .onboarding-text {
        padding: 0 1em 2em 1em;
        @media only screen and (max-width: $breakpoint-mobile) {
            padding: 0 1em 2em 1em;
        }
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        z-index: 1;
    }
    form {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-color: white;
    }
    .input-group {
        margin-bottom: 15px;
    }
    input {
        width: 100%;
        padding: 10px;
        margin-top: 5px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    .error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }
}
