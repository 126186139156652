/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

$primary-color: #2e54a7;
$primary-color-dark: #272895;
$primary-color-light: #4dd8f8;
$alternative-color: #ffffff;
$contrast-color: #ff9300;
$font-stack: "Rubik", "Poppins", sans-serif;
$alternative-font-color: #ffffff;

$breakpoint-medium: 1200px;
$breakpoint-mobile: 768px;
