/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "./variables";

body {
    font-family: $font-stack;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-weight: 300;

    z-index: 1;

    color: $primary-color;
    background-color: #fafafa !important;

    color: $primary-color-dark !important;
}

.App,
.App > div {
    color: $primary-color;
}

.App {
    h1 {
        font-size: 3.25rem;
        line-height: 4rem;
        font-weight: 700;
        color: $primary-color-dark;

        &.subtitle {
            font-weight: 400;
        }
    }

    h2 {
        font-size: 2rem;
        font-weight: 400;
        color: $primary-color-dark;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
        h1 {
            font-size: 2.5rem;
            line-height: 3rem;
        }

        h2 {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    p {
        font-size: 1.375rem;
    }
    @media only screen and (max-width: $breakpoint-mobile) {
        p {
            font-size: 1.1rem;
        }
    }

    input {
        border-radius: 6px;
        border-width: 1px;
    }

    a {
        color: $primary-color-dark;
        text-decoration: none;
        font-weight: 500;
        transition: all 150ms ease-in-out;
    }
    a:hover {
        color: $contrast-color;
    }

    .white-font {
        color: white !important;

        a {
            color: $primary-color-dark;
        }
        a:hover {
            color: $contrast-color;
        }
    }


    .error,
    div p.error {
        color: red !important;
        font-size: 1rem;
    }
    .nav-item {
        color: #272895 !important;
        font-weight: 500 !important;
        font-size: 20px;
        font-family: "Rubik", sans-serif;
        transition: all 150ms ease-in-out;
    }

    .nav-item:hover,
    .active {
        color: $contrast-color !important;
    }

    .very-small-text {
        font-size: 0.6rem;
    }

    .small-text {
        font-size: 0.8rem;
    }

    .large-text,
    .large-text p {
        font-size: calc(1rem + 2vmin) !important;
    }

    .white-background {
        background-color: white;
    }

    .app-container {
        display: flex;
        min-height: 100vh;
        flex-direction: column;

        max-width: 1500px;
        margin: 0 auto;
        position: relative;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .screen-404 {
        padding: 6em 2em 6em 4em;
        flex: 1;
    }

    // Centering may not work everywhere
    .center {
        align-items: center !important;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        text-align: center !important;
    }

    .bold {
        font-weight: bold;
    }

    .block-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    /* /////////////////////////// */

    .gradient-background {
        background: transparent
            linear-gradient(360deg, #51e5ff 0%, #272795 100%);
        h1,
        h2,
        h3,
        p {
            color: white !important;
        }
    }

    /* /////////////////////////// */
    .hero {
        padding: 13em 7em 7em 7em;
        opacity: 1;
        display: flex;

        @media only screen and (max-width: 1100px) {
            padding: 8em 5em 7em 5em;
        }
        hr {
            color: white !important;
            width: 21%;
            border: 1px solid white !important;

            @media only screen and (max-width: $breakpoint-mobile) {
                width: 100%;
            }

            @media only screen and (max-width: 1000px) {
                color: white !important;
                width: 50%;
                border: 1px solid white !important;
                margin: 2rem auto 2rem auto;
            }
        }

        @media only screen and (max-width: $breakpoint-mobile) {
            p.main-description {
                // padding-top: 1rem;
                margin-bottom: 2em;
            }
        }
        .phone-container {
            margin: 0rem auto 0rem auto;
            padding: 0rem 1rem 0rem 1rem;
            z-index: 20;
            max-width: 40%;
        }
        overflow: hidden;
        position: relative;
    }

    div.text-box {
        max-width: 60%;
        font-family: "Rubik", sans-serif;
    }
    @media only screen and (max-width: 1000px) {
        .hero {
            .text-box {
                text-align: center;
                max-width: 100%;
            }
            .phone-container {
                display: none;
            }
        }
    }

    @media only screen and (max-width: $breakpoint-mobile) {
        .hero {
            padding: 4rem 1rem;

            .text-box {
                text-align: center;
                width: 100%;
            }
        }
    }

    img.unblur {
        animation: unblurit 0.5s ease 0s;
    }
    @keyframes unblurit {
        from {
            filter: blur(10px);
        }

        to {
            filter: blur(0px);
        }
    }

    .hero::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: transparent
            linear-gradient(360deg, #51e5ff 0%, #272795 100%);
        z-index: 1; /* Below everything */
    }

    .hero::after {
        content: "";
        position: absolute;

        top: -9rem;
        right: -7em;
        width: 40%;
        height: 160%;

        background-image: url("../public/dna.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.5;
        z-index: 0;
        transform: rotate(40deg);
        background-size: 150%;

        @media only screen and (max-width: 1515px) {
            top: -11rem;
            right: -8rem;
            width: 48%;
            height: 160%;
            transform: rotate(35deg);
            background-size: 120%;
        }
        @media only screen and (max-width: 1400px) {
            top: -11rem;
            right: -10rem;
            width: 48%;
            height: 160%;
            transform: rotate(30deg);
            background-size: 130%;
        }
        @media only screen and (max-width: 1100px) {
            top: -11rem;
            right: -10rem;
            width: 48%;
            height: 160%;
            transform: rotate(30deg);
            background-size: 130%;
        }
        @media only screen and (max-width: 1000px) {
            top: -5rem;
            right: -17rem;
            width: 48%;
            height: 160%;
            transform: rotate(15deg);
            background-size: 130%;
        }
        @media only screen and (max-width: $breakpoint-mobile) {
            display: none;
        }
    }

    .flex-container {
        margin: 0em 0em 0em 0em;
        padding: 3em 0em 3em 0em;
        @media only screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
            align-items: center;
            margin: 0em 0em 0em 0em;
            padding: 3em 0em 3em 0em;
        }
    }

    /* /////////////////////////// */

    .dark-box {
        background: $primary-color-dark;
        color: $alternative-font-color;
        .square-list-item {
            background-color: $alternative-color;
        }
    }
    .light-box {
        background: $primary-color-light;
        color: $primary-color;
    }

    .white-box {
        background-color: $alternative-color;
        border-radius: 10px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 100%;
        max-width: 800px;
        min-height: 10rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        color: $primary-color-dark !important;

        h2 {
            font-weight: bold;
            color: $primary-color !important;
        }
        p {
            color: $primary-color !important;
        }
    }

    .square-list-item {
        background: #2e54a6 0% 0% no-repeat padding-box;
        border-radius: 2px;
        display: inline-block;
    }

    .square-list-item-big {
        width: 0.6875rem;
        height: 0.6875rem;
        margin: 0.4rem 1.5rem 0.4rem 2.5rem;
        @media only screen and (max-width: $breakpoint-mobile) {
            margin: 0.15rem 0.5rem 0.15rem 0.5rem;
        }
    }

    .square-list-item-small {
        width: 0.5rem;
        height: 0.5rem;
        margin: 0.3rem 1rem 0.3rem 1rem;
    }
    .action-button {
        background-color: $contrast-color !important;
        color: $alternative-font-color !important;
        font-size: 1.5rem;
        font-weight: 500;
        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 1rem;
        }
    }

    .numbered-list {
        list-style: decimal;
    }

    .unnumbered-list {
        list-style: disc;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
        border: 1px solid black;
        border-radius: 5px;
        background-color: white;
        display: block;
        padding: 1em 1em 1em 1em;
        font-size: 0.8em;
        color: #111 !important;
    }

    .wrap-address {
        word-break: break-all;
    }

    .tooltip-button {
        cursor: pointer;
    }

    .tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: black;
        color: $alternative-font-color;
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 5px;
        white-space: nowrap;
        z-index: 100;
    }
}
