/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "./../../../variables";

.LoginForm {
    margin: 0.8em 0 0.3em 0;
    .form-group {
        margin-bottom: 0.5em;
    }

    input {
        width: 100%;
        padding: 8px;
        margin: 0.5em 0 0.5em 0;
    }

    .error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }

    button {
        margin: 1em 0 0 0;
    }
}
