/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

 @import "../../../variables";

.LoginButton {
    margin: 0em 2em 1em 1em;
    @media only screen and (max-width: $breakpoint-mobile) {
        margin: 0em 1em 1em 0em;
    }
    div > .btn {
        margin: 2em 0em 1em 0em;
    }
}
