/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

@import "./../../../variables";

.MultiFormContainer {
    font-size: 1.2em;
    color: #333;
    position: relative;
    z-index: 1;

    width: 50%;
    @media (max-width: 700px) {
        width: 80%;
    }

    .questionnaire-container {
        display: flex;
        justify-content: center; /* Centers horizontally */
        align-items: center; /* Centers vertically */
        width: 100%;
        height: 100%;

        h1,
        h2,
        h3,
        p {
            color: $primary-color-dark !important;
        }
    }

    .questionnaire {
        width: 20em;
        padding: 2em;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        text-align: left;

        @media only screen and (max-width: $breakpoint-mobile) {
            padding: 1rem;
        }

        h2,
        button {
            text-align: center;
            margin: 0 auto;
            display: block;
        }
    }

    // Overriding
    .nav-item {
        font-weight: 400 !important;
        font-size: 0.8em;
        padding: 0 5% 0 5%;

        @media (max-width: 1100px) {
            padding: 0 0.5em 0 0.5em;
        }
    }

    p.no-account {
        margin-top: 1em;
        font-size: 0.8em;
        color: $primary-color !important;
    }
}
