/**
 * @title 
 * @author Beligent
 * @notice 
 * Copyright 2024
 */

@import "./../../../variables";

.Platform {
    .content {
        padding: 4em 3em 6em 4em;
        flex: 1;
    }
    flex: 1;
    display: flex;

    .platform-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        background-color: #fff;
    }

    .layout-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        background-color: #fff;
    }

    /* Sidebar styling */
    .sidebar {
        width: 25%;
        min-width: 18em;
        // background-color: #2c3e50;
        color: #000000;
        // padding: 20px;
        box-sizing: border-box;
        // border-right: 1px solid #ecf0f1;
        transition: width 0.3s;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    }

    .sidebar h2 {
        margin-top: 0;
    }

    .sidebar ul {
        list-style: none;
        padding: 0;
    }

    .sidebar ul li {
        margin: 10px 0;
    }

    /* Main content styling */
    .content {
        flex-grow: 1;
    }

    /* Responsive Design */
    @media (max-width: 768px) {
        .layout-container {
            flex-direction: column;
        }

        .sidebar {
            width: 100%;
            height: auto;
        }

        .content {
            width: 100%;
        }
    }

    .hero {
        flex-direction: row;
        @media only screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
            margin: 0em 0em 0em 0em;
            padding: 3em 0em 3em 0em;
        }

        flex: 1;
        position: relative;
        z-index: 0;
        align-items: center;
    }

    .welcome-message {
        padding: 0em 0em 0em 0em;
    }

    .connection-info {
        width: 30%;
        min-width: 540px;

        position: absolute;
        top: -1em;
        right: 0px;
    }

    div.text-box {
        width: 50%;
        max-width: 100%;
        padding-right: 3em;

        z-index: 1;

        @media only screen and (max-width: $breakpoint-mobile) {
            padding-right: 0;
        }
    }

    .connect-wallet-button {
        background: -webkit-linear-gradient(left, #60c657, #35aee2);
        background-size: 200% 200%;
        animation: gradient-animation 4s ease infinite;
    }

    .mint-button {
        background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
        background-size: 200% 200%;
        animation: gradient-animation 4s ease infinite;
        margin-right: 15px;
    }

    /* KeyFrames */
    @-webkit-keyframes gradient-animation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @-moz-keyframes gradient-animation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @keyframes gradient-animation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
}
