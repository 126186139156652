/* @title */
/* @author Beligent */
/* @notice */
/* Copyright 2024 */

html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 100%;
}
